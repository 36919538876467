<template>
  <div class="home">
    <div class="home-content" v-if="isChain==1">
      <div class="user-info">
        <img src="@/assets/imgs/avatar.png" alt="" />
        <div>
          <p>登录账号：{{ userInfo.account}}</p>
          <p>注册时间：{{ userInfo.created_at }}</p>
        </div>
      </div>
      <div class="shop-info">
        <div class="shop-name">
          <h4>连锁店名</h4>
          <p>{{userInfo.name}}</p>
        </div>
        <div class="shop-name">
          <h4>角色</h4>
          <p>{{userInfo.chain_job==1?'管理员':userInfo.chain_job==2?'运营':'财务'}}</p>
        </div>
        <div class="shop-num">
          <h4>店铺数量</h4>
          <p>{{userInfo.shop_num}}</p>
        </div>
      </div>
    </div>
    <!-- //////////////门店////////////////// -->
    <div class="home-content" v-else>
        <div class="user-info">
        <img src="@/assets/imgs/avatar.png" alt="" />
        <div>
          <p>当前门店：{{ userInfo.name}}</p>
          <p>登录账号：{{ userInfo.account}}</p>
          <p>注册时间：{{ userInfo.created_at }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getUserInfo_api} from "@/api/user.js"
import {getUserInfo_api1} from "@/api/store/user.js"
import local from "@/utils/local.js"
export default {
  data() {
    return {
       userInfo:'',
       isChain:""
    }
  },
  methods: {
    //获取用户信息
    async getuserInfo() {
      let isChain=local.get("user")
      if(isChain==1){
        let res=await getUserInfo_api()
      this.userInfo=res.data.data
      }else{
      let data=await getUserInfo_api1()
        this.userInfo=data.data.data
        local.set("IsUp",this.userInfo.is_up)
      }
    }
  },
  created() {
    this.getuserInfo()
    this.isChain=local.get("user")
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  background: #eee;
  padding: 22px;
  box-sizing: border-box;
  .home-content {
    background: #ffffff;
    border-radius: 5px;
    padding: 30px;
    box-sizing: border-box;
    .user-info {
      display: flex;
      align-items: center;
      img {
        width: 110px;
        height: 110px;
        margin-right: 14px;
      }
      p {
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        margin-top: 10px;
      }
    }
    .shop-info {
      display: flex;
      h4 {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-top: 30px;
      }
      p {
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        margin-top: 21px;
      }
      .shop-name{
          width: 360px;
          margin-left: 20px;
      }
      .shop-num{
          text-align: center;
      }
    }
  }
}
</style>